<template>
  <b-row class="my-3">
    <b-col
      v-for="(widget, key) in widgets"
      :key="key"
    >
      <b-card :class="widget.customClass">
        <b-media no-body>
          <b-media-aside
            class="mr-2"
          >
            <b-avatar
              size="48"
              :variant="`${widget.color}`"
            >
              <feather-icon
                size="24"
                :icon="`${widget.icon}`"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0">
              <h2
                v-if="isNaN(Number(widget.value))"
                class="mb-0"
              >
                {{
                  widget.value
                }}
              </h2>
              <h2
                v-else
                class="mb-0"
              >
                {{ widget.value | money }}
              </h2>
            </h4>
            <b-card-text class="font-small-3 mb-0">
              {{ widget.name }}
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar
} from 'bootstrap-vue'
export default {
  name: 'GameHistoryWidgets',
  components: {
    BAvatar,
  },
  props: {
    widgetsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      defaultWidgets: [
        {
          icon: 'ActivityIcon',
          name: `${this.$t('pages.mini_games.total_games')}`,
          value: 0,
          color: 'light-info',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          name: `${this.$t('pages.mini_games.total_bet')}`,
          value: 0,
          color: 'light-warning',
          customClass: 'mb-2 mb-xl-0',
        },
        { icon: 'CoffeeIcon', name: `${this.$t('games.total_income')}`, value: 0,
          color: 'light-success',
          customClass: 'mb-2 mb-xl-0',
         },
      ],
    }
  },
  computed: {
    widgets() {
      return this.widgetsData.length > 0
        ? this.widgetsData
        : this.defaultWidgets
    },
  },
}
</script>
